const cms = (path) => {
  const fetchData = async (path) => {
    try {
      if (path.startsWith("/")) {
        path.replace("/", "");
      }
      const url = "https://cms.cadenmfink.workers.dev/cadenmf.com/" + path;
      const projectsResponse = await fetch(url);
      const projectsData = await projectsResponse.json();
      return projectsData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return fetchData(path);
};

export default cms;
