import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const UpdatePageDescription = ({ pageDescription }) => {
  return (
    <Helmet>
      <meta name="description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
  );
};

UpdatePageDescription.propTypes = {
  pageDescription: PropTypes.string.isRequired,
};

export default UpdatePageDescription;
