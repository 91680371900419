import React, { useState } from "react";
import {
  getDevice,
  isAndroid,
  isBlackberry,
  isChrome,
  isDesktopBrowser,
  isEdge,
  isFirefox,
  isOpera,
  isOtherBrowser,
  isSafari,
  isWindowsPhone,
  isiPad,
  isiPhone,
} from "../functions/user-agents";
import { UserAgentDisplayImage } from "../modules";

const UserAgentDisplay = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const openTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const closeTooltip = () => {
    setTooltipOpen(false);
  };

  const style = () => {
    switch (true) {
      case isiPhone():
      case isiPad():
        return { background: "white", color: "black" };
      case isAndroid():
        return { background: "black", color: "white" };
      case isWindowsPhone():
        return { background: "white", color: "black" };
      case isBlackberry():
        return { background: "white", color: "black" };
      case isDesktopBrowser():
        const browser = isDesktopBrowser();
        switch (browser) {
          case "Edge":
            return { background: "blue", color: "white" }; // Example styles for Edge
          case "Chrome":
            return { background: "green", color: "white" }; // Example styles for Chrome
          case "Safari":
            return { background: "lightblue", color: "black" }; // Example styles for Safari
          case "Firefox":
            return { background: "orange", color: "white" }; // Example styles for Firefox
          case "Opera":
            return { background: "red", color: "white" }; // Example styles for Opera
          case "Other Browser":
            return { background: "black", color: "white" }; // Example styles for other browsers
          default:
            return { background: "white", color: "black" }; // Default styles
        }
      default:
        return { background: "white", color: "black" }; // Default styles
    }
  };

  return (
    <div className="ua-container">
      {tooltipOpen && (
        <div className={`user-agent-display-tooltip`} style={style()}>
          You are on {getDevice(true)}
        </div>
      )}
      <div
        onClick={openTooltip}
        onMouseLeave={closeTooltip}
        className="user-agent-display"
        style={style()}
      >
        {isiPhone() && (
          <UserAgentDisplayImage
            src="https://raw.githubusercontent.com/MysticalMike60t/images/5973aed9cc7a337e24e5e074a0c1e215c10b11e3/lib/svg/icons/apple-black-logo-svgrepo-com.svg"
            userAgent="iPhone"
          />
        )}
        {isiPad() && (
          <UserAgentDisplayImage
            src="https://raw.githubusercontent.com/MysticalMike60t/images/5973aed9cc7a337e24e5e074a0c1e215c10b11e3/lib/svg/icons/apple-black-logo-svgrepo-com.svg"
            userAgent="iPad"
          />
        )}
        {isAndroid() && (
          <UserAgentDisplayImage
            src="https://raw.githubusercontent.com/MysticalMike60t/images/4f5045778e66838b4bc3b4ee848e157ca50e6e70/lib/svg/icons/android-color-svgrepo-com.svg"
            userAgent="Android"
          />
        )}
        {isWindowsPhone() && (
          <UserAgentDisplayImage
            src="https://raw.githubusercontent.com/MysticalMike60t/images/8bd42b67fcbcf1dfb083ca96bbb5e6587349a7ff/lib/svg/icons/windows-applications.svg"
            userAgent="Windows Phone"
          />
        )}
        {isBlackberry() && (
          <UserAgentDisplayImage
            src="https://raw.githubusercontent.com/MysticalMike60t/images/8bd42b67fcbcf1dfb083ca96bbb5e6587349a7ff/lib/svg/icons/blackberry-color-svgrepo-com.svg"
            userAgent="BlackBerry"
          />
        )}
        {isDesktopBrowser() && (
          <>
            {isEdge() && (
              <UserAgentDisplayImage
                isDesktopBrowser={true}
                src="https://raw.githubusercontent.com/MysticalMike60t/images/16bddd2b737e83e82a3defb45735af9ef87a881b/lib/svg/icons/edge-svgrepo-com.svg"
                userAgent="Edge"
              />
            )}
            {isChrome() && (
              <UserAgentDisplayImage
                isDesktopBrowser={true}
                src="https://raw.githubusercontent.com/MysticalMike60t/images/16bddd2b737e83e82a3defb45735af9ef87a881b/lib/svg/icons/chrome-svgrepo-com(1).svg"
                userAgent="Chrome"
              />
            )}
            {isSafari() && (
              <UserAgentDisplayImage
                isDesktopBrowser={true}
                src="https://raw.githubusercontent.com/MysticalMike60t/images/16bddd2b737e83e82a3defb45735af9ef87a881b/lib/svg/icons/safari-svgrepo-com.svg"
                userAgent="Safari"
              />
            )}
            {isFirefox() && (
              <UserAgentDisplayImage
                isDesktopBrowser={true}
                src="https://raw.githubusercontent.com/MysticalMike60t/images/16bddd2b737e83e82a3defb45735af9ef87a881b/lib/svg/icons/firefox-svgrepo-com.svg"
                userAgent="Firefox"
              />
            )}
            {isOpera() && (
              <UserAgentDisplayImage
                isDesktopBrowser={true}
                src="https://raw.githubusercontent.com/MysticalMike60t/images/16bddd2b737e83e82a3defb45735af9ef87a881b/lib/svg/icons/opera-svgrepo-com.svg"
                userAgent="Opera"
              />
            )}
            {isOtherBrowser() && (
              <UserAgentDisplayImage
                isDesktopBrowser={true}
                src="https://raw.githubusercontent.com/MysticalMike60t/images/872668f312041833940b383698fb489e7b6c4265/lib/svg/icons/internet-svgrepo-com.svg"
                userAgent="Unknown-Browser"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserAgentDisplay;
