import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Modal = ({
  strong,
  text,
  buttons,
  backgroundEnabled = true,
  closeModalButtonEnabled = true,
  name,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.setItem(`${name}-modalState`, "false");
  };
  useEffect(() => {
    if (localStorage.getItem(`${name}-modalState`) === "false") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  }, [name]);
  return (
    <>
      {isModalOpen && (
        <>
          <div className="modal" id="global_modal">
            {strong && (
              <>
                <strong className="modal-strong-text">{strong}</strong>{" "}
              </>
            )}
            <p className="modal-text">{text}</p>
            {buttons &&
              buttons.map((button, index) => (
                <button
                  className="modal-button"
                  key={index}
                  onClick={button.onClick}
                >
                  {button.text}
                </button>
              ))}
            {closeModalButtonEnabled && (
              <button
                className="modal-button-close"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
            )}
          </div>
          {backgroundEnabled && <div className="modal-background-blur"></div>}
        </>
      )}
    </>
  );
};

Modal.propTypes = {
  strong: PropTypes.string,
  text: PropTypes.string,
  buttons: PropTypes.array,
  backgroundEnabled: PropTypes.bool,
  closeModalButtonEnabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default Modal;
