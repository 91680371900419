const endpoints = {
  downloads: {
    main: {
      domain: "https://goblin-famous-moray.ngrok-free.app/",
      folder: "files/",
      items: {
        auto_clicker: "AutoClicker.exe",
      },
    },
  },
};
export default endpoints;
