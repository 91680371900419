const initialState = {
  isPopupOpen: false,
  willContinue: false // Adjusted to default to false
};

const controlPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_POPUP":
      return {
        ...state,
        isPopupOpen: true
      };
    case "CLOSE_POPUP":
      return {
        ...state,
        isPopupOpen: false
      };
    case "WILL_CONTINUE":
      return {
        ...state,
        willContinue: action.payload
      };
    case "TOGGLE_WILL_CONTINUE":
      return {
        ...state,
        willContinue: !state.willContinue
      };
    default:
      return state;
  }
};

export default controlPopupReducer;
