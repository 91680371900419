import React from "react";
import PropTypes from "prop-types";

const TermDefinitionComponent = ({ term, definition }) => {
  return (
    <div className="page-container defs">
      <div className="page-section">
        <h2>{term}</h2>
        <p>{definition}</p>
      </div>
    </div>
  );
};

TermDefinitionComponent.propTypes = {
  term: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
};

export default TermDefinitionComponent;
