import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const UpdatePageUrl = ({ path }) => {
  return (
    <Helmet>
      <meta
        property="og:url"
        content={"https://cadenmf.com" + path}
        og="http://opengraphprotocol.org/schema/"
      />
      <link rel="shortlink" href={"https://cadenmf.com" + path} />
      <link rel="canonical" href={"https://cadenmf.com" + path} />
      <meta name="twitter:url" content={"https://cadenmf.com" + path} />
    </Helmet>
  );
};

UpdatePageUrl.propTypes = {
  path: PropTypes.string.isRequired,
};

export default UpdatePageUrl;
