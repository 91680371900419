export function isiPhone() {
  return /iPhone/i.test(navigator.userAgent);
}

export function isiPad() {
  return /iPad/i.test(navigator.userAgent);
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export function isWindowsPhone() {
  return /Windows Phone/i.test(navigator.userAgent);
}

export function isBlackberry() {
  return /BlackBerry/i.test(navigator.userAgent);
}

export function isMobile() {
  return (
    isWindowsPhone() || isBlackberry() || isAndroid() || isiPad() || isiPhone()
  );
}

export function isEdge() {
  return (
    /Edge/i.test(navigator.userAgent) ||
    /Edg\//i.test(navigator.userAgent) ||
    /EdgA\//i.test(navigator.userAgent)
  );
}

export function isChrome() {
  return /Chrome/i.test(navigator.userAgent) && !isEdge();
}

export function isSafari() {
  return /Safari/i.test(navigator.userAgent) && !isChrome() && !isEdge();
}

export function isFirefox() {
  return /Firefox/i.test(navigator.userAgent);
}

export function isOpera() {
  return /Opera|OPR/i.test(navigator.userAgent);
}

export function isOtherBrowser() {
  return !isEdge() && !isChrome() && !isSafari() && !isFirefox() && !isOpera();
}

export function isDesktopBrowser() {
  if (!isMobile()) {
    if (isEdge()) {
      return "Microsoft Edge";
    } else if (isChrome()) {
      return "Chrome";
    } else if (isSafari()) {
      return "Safari";
    } else if (isFirefox()) {
      return "Firefox";
    } else if (isOpera()) {
      return "Opera";
    } else if (isOtherBrowser()) {
      return "Other Browser";
    } else {
      return "Desktop Computer";
    }
  }
  return false;
}

function getGrammarPrefix(device) {
  if (device.match(/^(i|A|E|O|U)/) && !device.includes("Opera")) {
    return "an";
  } else if (device.match(/^(Windows Phone|BlackBerry)/)) {
    return "a";
  } else {
    return "";
  }
}

export function getDevice(withGrammar = false) {
  const device = isiPhone()
    ? "iPhone"
    : isiPad()
    ? "iPad"
    : isAndroid()
    ? "Android"
    : isWindowsPhone()
    ? "Windows Phone"
    : isBlackberry()
    ? "BlackBerry"
    : isEdge()
    ? "Microsoft Edge"
    : isChrome()
    ? "Chrome"
    : isSafari()
    ? "Safari"
    : isFirefox()
    ? "Firefox"
    : isOpera()
    ? "Opera"
    : isOtherBrowser()
    ? "Unknown Browser"
    : isDesktopBrowser()
    ? "Desktop"
    : "Unknown Device";

  return withGrammar ? `${getGrammarPrefix(device)} ${device}` : device;
}
