import React from "react";
import Loader from "./Loader";

const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20%",
      }}
    >
      <Loader />
    </div>
  );
};

export default Loading;
