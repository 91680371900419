import PropTypes from "prop-types";

const EmailBasic = ({ children }) => (
  <a
    href="mailto:support@d3vop.com"
    className="section-link"
    target="_blank"
    rel="noopen noreferrer"
    title="Email Caden Finkelstein"
    aria-label="Email Caden Finkelstein"
  >
    support@d3vop.com
    {children}
  </a>
);

EmailBasic.propTypes = {
  children: PropTypes.any,
};

export default EmailBasic;
