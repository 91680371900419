import React from "react";
import PropTypes from "prop-types";

const UserAgentDisplayImage = ({
  src,
  userAgent,
  isDesktopBrowser = false,
}) => {
  return (
    <div
      className={`user-agent-container ${
        isDesktopBrowser ? "desktop-true" : "desktop-false"
      }`}
    >
      <img src={src} className={`${userAgent.toLowerCase()}`} alt="User Agent" loading="lazy" />
      {isDesktopBrowser && (
        <img
          src="https://raw.githubusercontent.com/MysticalMike60t/images/e54f9630ee8e6d438025904bade80b70f233fcdb/lib/svg/icons/monitor-svgrepo-com.svg"
          alt="Desktop Monitor"
          className="desktop"
          loading="lazy"
        />
      )}
    </div>
  );
};

UserAgentDisplayImage.propTypes = {
  src: PropTypes.string.isRequired,
  userAgent: PropTypes.string.isRequired,
  isDesktopBrowser: PropTypes.any,
};

export default UserAgentDisplayImage;
