import PropTypes from "prop-types";

import UpdatePageDescription from "./UpdatePageDescription";
import UpdatePageTitle from "./UpdatePageTitle";
import UpdatePageUrl from "./UpdatePageUrl";

const UpdatePageMeta = ({ title, description, path }) => {
  return (
    <>
      <UpdatePageTitle pageTitle={title} />
      <UpdatePageDescription pageDescription={description} />
      <UpdatePageUrl path={path} />
    </>
  );
};

UpdatePageMeta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default UpdatePageMeta;
