import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const UpdatePageTitle = ({ pageTitle }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta
        property="og:title"
        content={pageTitle}
        og="http://opengraphprotocol.org/schema/"
      />
      <meta
        property="og:site_name"
        content={pageTitle}
        og="http://opengraphprotocol.org/schema/"
      />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:text:title" content={pageTitle} />
      <meta name="apple-mobile-web-app-title" content={pageTitle} />
      <meta name="application-name" content={pageTitle} />
    </Helmet>
  );
};

UpdatePageTitle.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default UpdatePageTitle;
