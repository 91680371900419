const colors = {
  primary: "#ae7eec",
  primaryDark: "#704b97",
  primaryDesaturated: "#8c6bb8",
  secondary: "#5448c8",
  accent: "#e3b23c",
  loading: {
    tile: {
      default: "#515151",
      image: "#515151",
    },
  },
};

export default colors;
