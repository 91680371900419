const pageDefaults = {
  title: "Caden M F | My Portfolio | Developer & Cyber Security Specialist",
  titles: {
    home: {
      main: "Caden M F | My Portfolio | Developer & Cyber Security Specialist",
      about: "About | Caden Finkelstein",
      works: "Works | Caden Finkelstein",
      tools: "Tools | Caden Finkelstein",
      ads: "Ads | Caden Finkelstein",
      footer: "Ads | Caden Finkelstein"
    },
    api: "APIs & Scripts | Caden M F | Developer & Cyber Security Specialist",
    downloads: {
      main: "Downloads | Caden M F | Developer & Cyber Security Specialist",
      os: "OS Downloads | Caden M F | Developer & Cyber Security Specialist"
    },
    modding: {
      main: "Modding | Caden M F | Developer & Cyber Security Specialist",
      spt: {
        main: "SPT | Caden M F | Developer & Cyber Security Specialist",
        resources:
          "SPT Resources | Caden M F | Developer & Cyber Security Specialist"
      },
      modPacks: "Mod-Packs | Caden M F | Developer & Cyber Security Specialist"
    },
    ads: {
      main: "Ads | Caden M F | Developer & Cyber Security Specialist",
      boyfriend:
        "Kiyomi's Discord Server | Caden M F"
    },
    press: {
      main: "Press | Caden M F | Developer & Cyber Security Specialist"
    },
    github: {
      main: "GitHub | Caden M F | Developer & Cyber Security Specialist",
      auth: "GitHub Auth | Caden M F | Developer & Cyber Security Specialist"
    },
    auth: {
      main: "Auth | Caden M F | Developer & Cyber Security Specialist"
    },
    dev: {
      main: "Dev | Caden M F | Developer & Cyber Security Specialist"
    },
    search: "Search | Caden M F | Developer & Cyber Security Specialist",
    art: "Art | Caden M F | Developer & Cyber Security Specialist",
  },
  description:
    "A developer with a passion for inovation, and for progress. Working with all browsers, companies, and services to learn them, and create the future. I work with Javascript, CSS, HTML, SQL, Microsoft Edge, Google, Apple, Firefox, Mozila, etc.",
  descriptions: {
    api: "Discover our array of APIs and scripts for seamless workflows and task automation. With clear documentation and ready-to-use solutions, elevate your projects effortlessly.",
    downloads: {
      main: "Discover our diverse range of downloads: software, templates, guides, and more. Enhance workflows, increase efficiency, and foster collaboration. Elevate your projects now!",
      os: "Discover our diverse range of downloads: software, templates, guides, and more. Enhance workflows, increase efficiency, and foster collaboration. Elevate your projects now!."
    },
    modding: {
      main: "Modding resources and files",
      spt: {
        main: "Single Player Tarkov Resources and Files",
        resources: "Single Player Tarkov Resources"
      },
      modPacks: "Single Player Tarkov Mod Packs"
    },
    ads: {
      main: "Advertisements",
      boyfriend:
        "Check out Kiyomi's Discord server! Join his gaming streams on Genshin Impact and engaging podcasts covering a wide array of topics."
    },
    press: {
      main: "Press releases, news, and sources."
    },
    github: {
      main: "My GitHub.",
      auth: "Gihub Authentication to view the /github page."
    },
    auth: {
      main: "Main authentication page."
    },
    dev: {
      main: "Development tips, tools, and miscellaneous items for anything and everything!"
    },
    search: "Search all pages on my site!",
    art: "Artwork and designs by Caden M Finkelstein."
  },
  page: {
    home: {
      description: {
        term: "Developer",
        title:
          "I am a Developer and Cyber Security Specialist from the United States.",
        description:
          " Dedicated to advancing the future of the modern web through expertise in web development. Continuously learning and mastering the intricate workings of web technologies and services to enhance security, and optimize performance. Passionate about leveraging this knowledge to improve defenses, or help law enforcement.",
        email: "support@d3vop.com"
      }
    }
  }
};

export default pageDefaults;