import { lazy } from "react";

import * as GlobalModules from "../../global/modules";
import * as GlobalComponents from "../../global/components";

const { EmailBasic } = GlobalModules;
const {
  Header,
  Layout,
  LayoutLinks,
  Loader,
  Loading,
  TermDefinition,
} = GlobalComponents;

const NoPage = lazy(() => import("../../global/pages/errors/NoPage"));

export {
  Header,
  Layout,
  LayoutLinks,
  Loader,
  Loading,
  TermDefinition,
  EmailBasic,
  NoPage,
};
